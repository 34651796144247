.search {
    width: 500px;
}


.divider{ margin:2rem 4rem 3.5rem 4rem; background:#e4e4e4; opacity:0.1; min-width:auto; width:auto; }

.header{
  .back{ width:4.4rem; height:4.4rem; margin:1rem 0 0 2rem;
    span{ width:4.4rem; height:4.4rem; display:flex; justify-content:center; align-items:center;;
      svg{ display:block !important; width:2.4rem; height:2.4rem; z-index:3; color:transparent; }
    }
  }
  .headerBackground{ position:absolute; left:0; right:0; top:0; height:42rem;
    .headerBackgroundImage{ background-image:url('../../../../resources/images/headerEvents.jpg'); background-repeat:no-repeat; background-position:left top; background-size:cover; width:100%; height:100%; }
    .headerBackgroundGradient{ background:linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%); position:absolute; left:0; right:0; top:0; bottom:0;; }
  }
  h1{ font-size:3.6rem; line-height:4rem; color:#fff; font-weight:500; margin:11.4rem 0 0 0; position:relative; z-index:2; }
  p{ font-size:1.6rem; line-height:2.6rem; color:#fff; font-weight:300; margin:1rem 0 0 0; position:relative; z-index:2; }

  .mobileHeaderBackground{ position:absolute; left:0; right:0; top:0; height:33rem; z-index:2;
    .headerBackgroundImage{ background-image:url('../../../../resources/images/headerEvents-mob.jpg'); background-repeat:no-repeat; background-position:left top; background-size:cover; width:100%; height:100%; }
    .headerBackgroundGradient{ background:linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%); position:absolute; left:0; right:0; top:0; bottom:0;; }
  }
}


.form{ position:relative; z-index:3; max-width:86rem;
  h2{ margin:6rem 0 1rem 1.2rem; font-size:2.4rem; line-height:3.2rem; font-weight:500; color:#fff; }
  .collapseArea{ border-radius:1.2rem; overflow:hidden;
    &>div{ margin-bottom:0.3rem;
      &:last-of-type{ margin:0; }
    }
    .collapse{ background:#32343A; border:none;
      &>div{ border-bottom:none;
        &>div{ width:100%; padding:1.2rem 5rem 1.2rem 1.6rem;
          &>span{ position:absolute; right:1rem; top:1.5rem; width:2rem; height:2rem;
            svg{ width:1.6rem; height:1.6rem; }
          }
        }
      }
    }
  }
  .bank, .card{ background:#242731; border:none; border-radius:1.2rem; padding:5rem 0 2.5rem 0; cursor:pointer; margin-bottom:1rem;
    .title>div>div{ font-size:2rem; line-height:3.2rem; color:#fff; font-weight:500; text-align:center; }
  }
  .bank svg{ width:5.6rem; height:5.6rem; color:transparent; }
  .card svg{ width:5.8rem; height:4.4rem; color:transparent; margin:0.6rem 0; }
  .disclaimer{ color:#808191; font-size:1.2rem; line-height:2rem; font-weight:600; margin-top:6rem; }
}



@media only screen and (max-width: 767px){
  .header{ margin-left:-14.6rem; width:100vw;
      h1{ margin-top:2rem; text-align:center; max-width:26rem; margin-left:auto; margin-right:auto; font-weight:600; font-size:2.4rem; line-height:2.8rem; }
      p{ text-align:center; font-size:1.5rem; line-height:2.2rem; margin:1rem 2rem 0 2rem; }
  }
}



@media only screen and (max-width: 500px){
  .header{ margin-left:-13.1rem;
      .back{ margin:1rem 0 0 0.5rem; }
  }
}