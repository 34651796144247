.header {
    margin-top: -0.1rem;
    .headerBackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 42rem;
        .headerBackgroundImage {
            background-image: url('../../../resources/images/headerEvents.jpg');
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            width: 100%;
            height: 100%;
        }
        .headerBackgroundGradient {
            background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    .content {
        padding: 0;
    }
    .tools {
        margin: -0.8rem 0;
        button {
            width: 5.6rem;
            height: 5.6rem;
            border-radius: 1.2rem;
            border: 1px solid rgba(228, 228, 228, 0.2);
            color: transparent;
            &[ant-click-animating-without-extra-node='true'] {
                background: #3f8cff;
                border-color: #3f8cff;
            }
        }
        .sort {
            margin-left: 1.4rem;
            & > div {
                border-radius: 1.2rem;
                border: 1px solid rgba(228, 228, 228, 0.2);
                & > span {
                    color: #fff;
                    padding-right: 3rem;
                }
            }
        }
    }
}

.search {
    input {
        width: 50rem !important;
        font-size: 1.4rem;
        line-height: 2rem !important;
        color: #fff;
        font-weight: 600;
        padding: 0.8rem 2rem 0.8rem 4rem;
        height: 3.6rem;
        background-color: transparent !important;
        background-image: url('../../../resources/images/ico-search.svg');
        background-repeat: no-repeat;
        background-position: left center;
    }
    input + span {
        left: 0;
        background-color: transparent;
        button {
            border: none !important;
            width: auto !important;
            &:after {
                content: 'Submit';
                font-size: 1.3rem;
                font-weight: 600;
                transition: all 0.3s;
            }
            &:hover:after {
                color: #fff;
            }
            & > span {
                display: none !important;
            }
        }
    }
}

.toolbar {
    padding: 0;
    margin: 0 0 3rem 0;
    .sports,
    .organizations {
        margin-left: 1.6rem;
        & > div {
            padding: 1.8rem 4rem 1.8rem 2.2rem !important;
        }
    }
}

.orgName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2.4rem;
    line-height: 2.6rem;
    color: #fff;
    margin: 0 0 5rem 0;
    .logo {
        width: 8.6rem;
        height: 8.6rem;
        border-radius: 50%;
        flex: 1 0 8.6rem;
        max-width: 8.6rem;
        background: #fff;
        margin: 0 2rem 0 0;
        padding: 1rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

/* rwd */
@media only screen and (max-width: 1230px) {
    .search input {
        width: 38rem !important;
    }
}

.selected span {
    color: #5f75ee !important;
}

.unselected span {
    color: #808191 !important;
}
