.header{ width:100%; display:flex; align-items:center; justify-content:space-between;
  p{ font-size:1.6rem; line-height:2.6rem; color:#3DBAA2; font-weight:600; margin:0; }
  button{ height:5.6rem; border-radius:1.2rem; border:1px solid rgba(228,228,228,.1); font-size:1.4rem; line-height:2.4rem; color:#fff; font-weight:700; transition:all .3s;
    span{ width:1.7rem; height:1.7rem; margin-right:.7rem; color:transparent;
      svg{ width:100%; height:100%; object-fit:contain; }
    }
    &:hover{ background-color:#3F8CFF; color:#fff; }
  }
}

.eventDetails{ padding:0 0 10rem 0;
  .top{ display:flex; justify-content:space-between; align-items:stretch; background:linear-gradient(37.72deg, #4160AF 6.79%, #1E2F6E 83.29%); border-radius:2.4rem; padding:4.5rem 6rem;
    .details{ flex:1; padding-right:6rem;
      .category{ display:flex; align-items:center; justify-content:flex-start; margin:2rem 0 1rem 0; font-size:1.4rem; line-height:2rem; color:#ffffff; font-weight:500;
        span{ width:3.2rem; height:3.2rem; margin:0 0.7rem 0 0;
          svg{ width:100%; height:100%; object-fit:contain; }
        }
      }
      h1{ font-size:3.6rem; line-height:4.0rem; color:#fff; font-weight:600; margin:0 0 3.5rem 0; }
      ul{ margin:0; padding:0; list-style:none;
        li{ margin:0; padding:1rem 0; position:relative;
          h2{ font-size:1.0rem; line-height:2rem; color:#fff; font-weight:300; margin:0; text-transform:uppercase; letter-spacing:1px; }
          p{ font-size:1.6rem; line-height:2.6rem; color:#fff; margin:0; font-weight:600; }
          &:after{ content:''; width:20rem; height:1px; background:rgba(228,228,228,.1); position:absolute; left:0; bottom:0; }
          &:last-of-type{ padding-bottom:0;
            &:after{ display:none; }
          }
        }
      }
    }
    .register{ flex:1 0 24rem; max-width:24rem;
      .sponsor{ margin:0 0 2rem 0; border-radius:1.6rem; overflow:hidden;
        .logo{ height:14rem; background-color:#006940; padding:2rem; display:flex; justify-content:center; align-items:center;
          img{ width:100%; height:100%; object-fit:contain; }
        }
        .date{ background:#1F2128; padding:1.6rem 2rem; display:flex; justify-content:flex-start; align-items:center;
          &>div:nth-of-type(1){ font-size:4rem; line-height:3.4rem; color:#fff; font-weight:600; }
          &>div:nth-of-type(2){ padding-left:1rem;
            &>p:nth-of-type(1){ font-size:1.9rem; line-height:2.2rem; color:#fff; font-weight:600; margin:0; }
            &>p:nth-of-type(2){ font-size:1.2rem; line-height:1.6rem; color:#fff; font-weight:600; margin:0; text-transform:uppercase;; }
          }
        }
      }
      button{ height:4.4rem; width:100%; background:#3f8cff; border-radius:1.3rem; font-size:1.4rem; line-height:1.6rem; border:none; padding:1.4rem 2.4rem; color:#fff; font-weight:500; transition:all .3s;
        &:hover{ color:#3f8cff; background:#fff; }
      }
    }
  }
  .more{ padding:0 6rem 0 0; 
    .flex{ display:flex; justify-content:space-between; align-items:stretch; }
    h3{ margin:7rem 0 3.5rem 0; font-size:2.4rem; line-height:3.2rem; color:#fff; font-weight:500; }
    .desc{ font-size:1.6rem; line-height:2.6rem; color:#fff; font-weight:300; margin:0;
      p{ margin:3rem 0; font-size:1.6rem; line-height:2.6rem; color:#fff; font-weight:300; }
      ul, ol{ margin:3rem 0;
        li{ margin:0; font-size:1.6rem; line-height:2.6rem; color:#fff; font-weight:300; }
      }
      a{ color:#3F8CFF; text-decoration:underline;
        &:hover{ text-decoration:none; }
      }
    }
    .info{ flex:1; padding-right:6rem; }
    
    .register{ margin:3rem 0 0 0; text-align:center; display:none;
      button{ height:4.4rem; width:100%; max-width:30rem; background:#3f8cff; border-radius:1.3rem; font-size:1.4rem; line-height:1.6rem; border:none; padding:1.4rem 2.4rem; color:#fff; font-weight:500; transition:all .3s;
        &:hover{ color:#3f8cff; background:#fff; }
      }
    }

    .map{ flex:1 0 24rem; max-width:24rem;
      .googleMaps{ width:100%; height:24rem; border-radius:1.1rem; overflow:hidden; margin:0 0 2rem 0; }
      p{ margin:2rem 0; font-size:1.3rem; line-height:2rem; color:#fff; font-weight:500;
        a{ font-size:1.6rem; line-height:2.6rem; text-decoration:underline; font-weight:300;
          &:hover{ text-decoration:none; }
        }
      }
      button{ height:5.6rem; border-radius:1.2rem; border:1px solid rgba(228,228,228,.1); font-size:1.4rem; line-height:2.4rem; color:#fff; font-weight:700; width:100%; transition:all .3s;
        span{ width:1.7rem; height:1.7rem; margin-right:.7rem; color:transparent;
          svg{ width:100%; height:100%; object-fit:contain; }
        }
        &:hover{ background-color:#3F8CFF; color:#fff; }
      }
    }
  }
}


@media only screen and (max-width: 1200px){
  .eventDetails .more{ padding:0; }
}

@media only screen and (max-width: 1100px){
  .eventDetails .top{ padding:3rem 4rem; }
}


@media only screen and (max-width: 1050px){
  .eventDetails{
    .top{ padding:0; flex-direction:column; overflow:hidden;
      .register{ order:1; flex:auto; max-width:100%; padding:0;
        .sponsor{ padding:2rem 3rem; margin:0; background:#006940; max-height:8.4rem; border-radius:0; display:flex; justify-content:space-between; align-items:center;
          .logo{ order:2; padding:0; height:100%; }
          .date{ order:1; background:transparent; padding:0; }
        }
        button{ display:none; }
      }
      .details{ order:2; padding:3rem 3rem;
        .category{ margin:0; }
      }
    }
    .more{
      .register{ display:block; }
    }
  }
}


@media only screen and (max-width: 990px){
  .eventDetails .more .flex{ flex-direction:column; padding:0;
    .info{ padding-right:0; }
    .map{ max-width:100%;}
  }
}


@media only screen and (max-width: 767px){
  .eventDetails{ padding-bottom:15rem;
    .more .register{ position:fixed; bottom:0; left:0; right:0; background:#242731; padding:1.5rem 0 3.5rem 0; }
  }

  .header{ margin-left:-14.6rem; margin-top:-1.3rem; height:8rem; width:100vw; padding:0; position:relative; background:#1d1e23; z-index:2;
    button.back{ background-image:none !important; width:4.4rem; height:4.4rem; margin:1rem 0 0 2rem;
      span{ width:4.4rem; height:4.4rem; display:flex; justify-content:center; align-items:center;;
        svg{ display:block !important; width:2.4rem; height:2.4rem; z-index:3; color:transparent; }
      }
    }
    button.share{ background-image:none !important; width:4.4rem; height:4.4rem; margin:1rem 2rem 0 0;
      span{ width:4.4rem; height:4.4rem; display:flex; justify-content:center; align-items:center;;
        svg{ display:block !important; width:2.4rem; height:2.4rem; z-index:3; color:transparent; }
      }
    }
    p{ margin-top:1rem; }
  }
}



@media only screen and (max-width: 500px){
  .header{ margin-left:-13.1rem;
      button.back{ margin:1rem 0 0 0.5rem; }
      button.share{ margin:1rem 0.5rem 0 0; }
  }
}