.toolbar{ margin:0 0 4rem 0;
  h1{ font-size:3.6rem; line-height:4rem; color:#fff; font-weight:500; margin:0 0 0 0; position:relative; z-index:2; }
}

.buttons{ display:flex; justify-content:space-between; align-items:center; margin:4rem 0 6rem 0;
  .save{ height:5.6rem; font-size:1.4rem; display:flex; align-items:center; justify-content:center; border-radius:1.2rem; border:none; font-weight:600; color:#fff; padding:1rem 2.5rem;
    &>span{ width:2.2rem; height:2.2rem; margin:0 1.2rem 0 0;
      svg{ width:100%; height:100%; object-fit:contain; }
    }
  }
  .delete{ height:5.6rem; font-size:1.4rem; display:flex; align-items:center; justify-content:center; border-radius:1.2rem; background:#a00; border:none; font-weight:600; color:#fff; padding:1rem 2.5rem;
    &>span{ width:2.2rem; height:2.2rem; margin:0 1.2rem 0 0;
      svg{ width:100%; height:100%; object-fit:contain; }
    }
    &:hover{ background:#800; }
  }
  .back{ height:5.6rem; font-size:1.4rem; display:flex; align-items:center; justify-content:center; border-radius:1.2rem; border:none; font-weight:600; color:#bbb; padding:1rem 2.5rem; margin-left:-2.5rem;
    &:hover{ color:#fff; }
  }
}


@media only screen and (max-width: 400px){
  .buttons{ flex-direction:column;
    .back{ margin-left:0; margin-bottom:2rem; }
    .delete{ margin-bottom:3rem; }
  }
}