.search {
    width: 500px;
}

.toolbar {
    .sports,
    .organizations {
        margin-left: 8px;
    }
}

.card {
    .image {
        height: 150px;
        background: rgba(255, 255, 255, 0.3);
    }
    .description svg {
        margin-right: 8px;
    }
    .footer {
        margin: 16px -24px 0 -24px;
        padding: 16px 24px 0 24px;
        border-top: 1px solid #303030;
    }
}
