.header{
  .headerBackground{ position:absolute; left:0; right:0; top:0; height:42rem;
    .headerBackgroundImage{ background-image:url('../../resources/images/headerEvents.jpg'); background-repeat:no-repeat; background-position:left top; background-size:cover; width:100%; height:100%; }
    .headerBackgroundGradient{ background:linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%); position:absolute; left:0; right:0; top:0; bottom:0;; }
  }
  h1{ font-size:3.6rem; line-height:4rem; color:#fff; font-weight:500; margin:11.4rem 0 0 0; position:relative; z-index:2; }
  p{ font-size:1.6rem; line-height:2.6rem; color:#fff; font-weight:300; margin:1rem 0 0 0; position:relative; z-index:2; }
}

.subheader{ margin:0 0 5rem 0;
  .shcontent{ display:flex; justify-content:space-between; align-items:center; margin:-2rem -1.5rem 0 -1.5rem;
    &>.flex{ display:flex; justify-content:space-between; margin:0 -0.3rem;
      &>div{ padding:0 0.3rem; margin:0; }
    }
    &>div{ margin:0; padding:0 1.5rem; }
    .days{ display:flex; justify-content:flex-start; flex-wrap:wrap; max-width:29rem; flex:1 0 29rem; padding-left:0.5rem; padding-right:0.5rem;
      &>div{ flex:1 0 7rem; max-width:7rem; padding:0 1rem; margin-bottom:0; max-height:2rem;
        &>div>div{ max-height:2rem; min-height:2rem; }
      }
      label{ 
        span{ color:#808191; font-size:1.4rem; line-height:2rem; font-weight:600; }
        input[type="checkbox"]{ width:1.6rem; height:1.6rem; border:2px solid #e1e1e1; border-radius:.4rem; }
      }
    }
    .button{ height:5.6rem; background-color:#3F8CFF; font-size:1.4rem; line-height:2rem; font-weight:600; color:#fff; border:none; border-radius:1.2rem; padding:1.8rem 2rem; width:100%; text-align:center; transition:all .3s;
      &:hover{ background:#fff; color:#3F8CFF; }
    }
    .select{
      &>div{ background-color:#242731; }
      &>span{ background-color:#242731; right:1.1rem; }
    }
  }
}

.eventsList{
  ul{
    li{ position:relative; border-bottom:none !important; margin:0 0 3rem 0; background:#242731; border-radius:2.4rem; padding:2rem 12.5rem 2rem 2rem;
      &:after{ content:''; background:url('../../resources/images/arrowForward.svg') no-repeat; background-position:center; background-size:contain; width:1.8rem; height:3.6rem; position:absolute; right:3rem; top:50%; }
      .register{ position:absolute; right:2rem; top:2rem; height:4.4rem; background:#3f8cff; border-radius:1.3rem; font-size:1.4rem; line-height:1.6rem; border:none; padding:1.4rem 2.4rem; color:#fff; font-weight:500; transition:all .3s;
        &:hover{ color:#3f8cff; background:#fff; }
      }
      .event{ display:flex;
        &>div:nth-of-type(1){ flex:1 0 24rem; max-width:24rem; border-radius:1.6rem; height:20rem; border:1px solid #333333; overflow:hidden;
          .details{ display:flex; flex-direction:column; height:100%;
            .address{ flex:1; padding:3rem 2rem;
              p{ margin:0; font-size:1.3rem; line-height:2rem; color:#fff; font-weight:500; }
            }
          }
          .date{ background:#1F2128; flex:1 0 7rem; max-height:7rem; padding:1.5rem 2rem; display:flex; justify-content:flex-start; align-items:center;
            &>div:nth-of-type(1){ font-size:4rem; line-height:3.4rem; color:#fff; font-weight:600; }
            &>div:nth-of-type(2){ padding-left:1rem;
              &>p:nth-of-type(1){ font-size:1.9rem; line-height:2.2rem; color:#fff; font-weight:600; margin:0; }
              &>p:nth-of-type(2){ font-size:1.2rem; line-height:1.6rem; color:#fff; font-weight:600; margin:0; text-transform:uppercase;; }
            }
          }
        }
        &>div:nth-of-type(2){ flex:1; padding-left:2.5rem; }
      }
      &:nth-of-type(4n+1) .event > div:nth-of-type(1){ background:#3DBAA2; }
      &:nth-of-type(4n+2) .event > div:nth-of-type(1){ background:#FF7A68; }
      &:nth-of-type(4n+3) .event > div:nth-of-type(1){ background:#6C5DD3; }
      &:nth-of-type(4n+4) .event > div:nth-of-type(1){ background:#808191; }

      .eventTitle{ font-size:3rem; line-height:3.2rem; color:#fff; margin:3rem 0 0 0; font-weight:500;
        .category{ display:flex; align-items:center; justify-content:flex-start; margin:0 0 .5rem 0; font-size:1.4rem; line-height:2rem; color:#808191; font-weight:500;
          span{ width:3.2rem; height:3.2rem; margin:0 0.7rem 0 0;
            svg{ width:100%; height:100%; object-fit:contain; }
          }
        }
        a:hover{ color:#fff; text-decoration:underline; }
      }
      .eventAvatar{ flex:1 0 24rem; max-width:24rem; }
      .eventDescription{ padding-top:2.5rem; margin-top:2.5rem; border-top:1px solid rgba(228,228,228,.1);
        .eventFlex{ margin:0 -2.5rem 0 -2.5rem; display:flex; justify-content:space-between; width:100%;
          &>div{ padding:0 2.5rem; border-right:1px solid #5b5e69; flex:auto;
            &:last-of-type{ border-right:none; }
            h4{ font-size:1rem; line-height:1.2rem; text-transform:uppercase; letter-spacing:1px; color:#808191; font-weight:700; margin:0 0 0.5rem 0; }
            h4+div{ font-size:1.4rem; line-height:2rem; color:#fff; font-weight:700; }
          }
        }
      }
    }
  }
}

.eventsListMobile{
  ul{
    li{ position:relative; border-bottom:none !important; margin:0 0 1.4rem 0; background:#242731; border-radius:1.8rem; display:flex; flex-direction:column; padding:0; overflow:hidden;;
      &>div:nth-of-type(1){ flex:auto; padding:1.5rem 2rem; width:100%;
        .headerFlex{ display:flex; align-items:center; justify-content:space-between; width:100%;
          .date{ display:flex; justify-content:flex-start; align-items:center;
            &>div:nth-of-type(1){ font-size:4rem; line-height:3.4rem; color:#fff; font-weight:600; }
            &>div:nth-of-type(2){ padding-left:1rem;
              &>p:nth-of-type(1){ font-size:1.9rem; line-height:2.2rem; color:#fff; font-weight:600; margin:0; }
              &>p:nth-of-type(2){ font-size:1.2rem; line-height:1.6rem; color:#fff; font-weight:600; margin:0; text-transform:uppercase;; }
            }
          }
          &>div:nth-of-type(2){ display:flex; justify-content:flex-end; padding-left:2rem;
            &>div{ flex:none; width:auto;
              h4{ font-size:1rem; line-height:1.2rem; text-transform:uppercase; letter-spacing:1px; color:#ffffff; font-weight:400; margin:0 0 0.2rem 0; }
              h4+div{ font-size:1.8rem; line-height:2.4rem; color:#fff; font-weight:600; }
            }
          }
        }
      }
      &:nth-of-type(4n+1) > div:nth-of-type(1){ background:#3DBAA2; }
      &:nth-of-type(4n+2) > div:nth-of-type(1){ background:#FF7A68; }
      &:nth-of-type(4n+3) > div:nth-of-type(1){ background:#6C5DD3; }
      &:nth-of-type(4n+4) > div:nth-of-type(1){ background:#808191; }

      .eventDetails{ font-size:3rem; line-height:3.2rem; color:#fff; font-weight:500; flex:auto; width:100%; padding:2.5rem 0 1rem 0;
        .eventTitle{ padding:0 2rem;
          .category{ display:flex; align-items:center; justify-content:flex-start; margin:0 0 .5rem 0; font-size:1.4rem; line-height:2rem; color:#808191; font-weight:500;
            span{ width:2.4rem; height:2.4rem; margin:0 0.7rem 0 0;
              svg{ width:100%; height:100%; object-fit:contain; }
            }
          }
          .category + a{ font-size:2.2rem; line-height:2.5rem; font-weight:500; }
        }
      }
      .eventDescription{ padding:1.5rem 2rem 0 2rem; margin-top:1.5rem; border-top:1px solid rgba(228,228,228,.1);        
        h4{ font-size:1rem; line-height:1.2rem; text-transform:uppercase; letter-spacing:1px; color:#808191; font-weight:700; margin:0 0 0.5rem 0; }
        h4+div{ font-size:1.4rem; line-height:2rem; color:#fff; font-weight:700; }
      }
    }
  }
}


.ico24{
  &>span{ margin:0 -0.2rem; width:2.4rem !important; }
}


/* mobile */
.mobileHeaderBackground{ position:absolute; left:0; right:0; top:0; height:33rem;
  .headerBackgroundImage{ background-image:url('../../resources/images/headerEvents-mob.jpg'); background-repeat:no-repeat; background-position:left top; background-size:cover; width:100%; height:100%; }
  .headerBackgroundGradient{ background:linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%); position:absolute; left:0; right:0; top:0; bottom:0;; }
}
.topNavi{ display:flex; justify-content:space-between; margin:0 -0.5rem 2.5rem -0.5rem;
  .buttonDiv{ flex:1 0 50%; max-width:50%; padding:0 0.5rem;
    button{ border:none; width:100%; height:5.6rem; background:#242731; color:#A0A1AB; border-radius:1.2rem; font-size:1.6rem; line-height:2rem; padding:1.8rem 2rem 1.8rem 5.6rem; position:relative; text-align:left; font-weight:500; transition:all .3s;
      &:hover{ color:#fff; background:#3F8CFF;
        &:before{ opacity:1; }
      }
      &:before{ content:''; display:block; background-repeat:no-repeat; background-position:center; left:1.7rem; top:0; width:3rem; height:100%; opacity:0.4; }
    }
    .events:before{ background-color:transparent; background-image:url('../../resources/images/ico-document.svg'); background-size:1.8rem auto; background-position:0.6rem center; }
    .tasks:before{ background-color:transparent; background-image:url('../../resources/images/ico-tasks.svg'); background-size:3rem auto; background-position:center; }
  }
}


@media only screen and (max-width: 1400px){
  .subheader .shcontent .days{ flex:1 0 22rem; max-width:22rem; }
}

@media only screen and (max-width: 1280px){
  .subheader{ margin:0 0 3.5rem;
    .shcontent{ flex-wrap:wrap; justify-content:flex-start;
      &>div{ padding-bottom:1.5rem; }
      .days{ flex:1 0 29rem; max-width:29rem; }
    }
  }
  .eventsList{
    ul li .eventDescription .eventFlex{ flex-direction:column; margin:0;
      &>div{ border-right:none; padding:0; margin:0.8rem 0; }
    }
  }
}

@media only screen and (max-width: 1060px){
  .eventsList ul li .event > div:nth-of-type(1){ flex:1 0 16rem; max-width:16rem; }
}

@media only screen and (max-width: 880px){
  .eventsList ul li{ padding:2rem;
    &:after{ display:none;; }
    .event{ flex-direction:column;
      &>div:nth-of-type(1){ flex:auto; width:100%; max-width:24rem; }
      &>div:nth-of-type(2){ padding-left:0; width:100%; }
    }
  }
}