.header{
  .headerBackground{ position:absolute; left:0; right:0; top:0; height:42rem;
    .headerBackgroundImage{ background-image:url('../../resources/images/headerEvents.jpg'); background-repeat:no-repeat; background-position:left top; background-size:cover; width:100%; height:100%; }
    .headerBackgroundGradient{ background:linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%); position:absolute; left:0; right:0; top:0; bottom:0;; }
  }
  .buttons{ display:flex; justify-content:flex-end;
    button{ height:5.6rem; border-radius:1.2rem; border:1px solid #555; font-size:1.4rem; font-weight:600; margin:0 0 0 -1px;
      &:hover, &[ant-click-animating-without-extra-node="true"]{ background:#3F8CFF; border:1px solid #3F8CFF !important; color:#fff; }
      &.share, &.upload{ margin:0 0 0 0.8rem; display:flex; align-items:center;
        span{ width:1.7rem; height:1.7rem; display:flex; justify-content:center; align-items:center; margin:0 0.7rem 0 0;
          svg{ display:block !important; width:1.7rem; height:1.7rem; z-index:3; color:transparent; }
        }
      }
    }
  }
}


.player{ position:relative;
  header{ display:flex; justify-content:space-between; flex-wrap:wrap;
    .picture{ flex:1 0 25.6rem; max-width:25.6rem;
      &>div{ width:100%; }
      img{ width:100%; height:100%; object-fit:cover; border-radius:50%; }
    }
    .playerInfo{ flex:1; padding:0 2rem;
      h1{ font-size:4.6rem; line-height:5rem; color:#fff; font-weight:600; margin:4.5rem 0 0 0; }
      ul{ display:flex; justify-content:flex-start; list-style:none; margin:0.5rem 0 1rem 0; padding:0;
        li{ padding:0 1.5rem 0 1.3rem; position:relative; color:#808191; font-size:1.2rem; line-height:1.6rem; white-space:nowrap;
            &:before{ content:''; display:block; width:.8rem; height:.8rem; position:absolute; left:0; top:.4rem; border-radius:50%; }
            &.place:before{ background:#0049C6; }
            &.height:before{ background:#7FBA7A; }
            &.weight:before{ background:#2B9CC5; }
        }
      }
      .misc{
        p{ margin:1rem 0 0 0; font-size:1.6rem; line-height:2rem; color:#fff; font-weight:500; }
      }
    }
    .playerScore{ flex:1 0 20%; max-width:20%; text-align:center;
      .score{ margin:3rem 0 0 0; font-size:8.3rem; line-height:8rem; font-weight:600; color:rgba(128,129,145,.6); }
      p{ font-size:1.7rem; margin:0.5rem 0; line-height:2.4rem; color:#fff; font-weight:600; }
      img{ display:inline-block; }
    }
    .playerStats{ margin:3.5rem 0; flex:1 0 100%; max-width:100%;
      ul{ margin:0; padding:0; display:flex; justify-content:flex-start; align-items:center; flex-wrap:wrap; list-style:none;
        li{ margin:0.5rem; padding:1.2rem 2rem; border-radius:1.2rem; background:rgba(36, 39, 49, 1); color:rgba(255,255,255,.4); font-size:1.6rem; line-height:2.2rem; font-weight:500;
          label{ color:#fff; margin-right:1rem; }
        }
      }
    }
  }

  .about{ margin-top:1rem;
    p{ font-size:1.6rem; line-height:2.6rem; color:#fff; margin:2rem 0; max-width:67rem; font-weight:300; }
    a{ font-size:1.6rem; line-height:2.6rem; text-decoration:underline; color:rgba(255,255,255,.4); text-decoration:underline;
      &:hover{ text-decoration:none; }
    }
  }

  .records{
    header{ margin:7rem 0 3rem 0; display:flex; justify-content:space-between; align-items:center;
      h2{ margin:0; }
      button{ color:#808191; text-decoration:underline; padding:0; margin:0; border:none;
        &:hover{ text-decoration:none; background:transparent; }
      }
    }
    .recordsData{ margin:6rem 0 0 0; display:flex; justify-content:space-between; border-radius:2.4rem; background:#242731; padding:3rem;
      .record{ flex:1 0 33.33%; max-width:33.33%; text-align:center; padding:0 0 1rem 0; position:relative;
        &:after{ content:''; width:1px; height:10rem; background:#3b3e48; position:absolute; right:0; top:50%; margin-top:-5rem; }
        &:last-of-type:after{ display:none; }

        .icon{ height:2.5rem; display:flex; justify-content:center; align-items:center; margin:0 0 0.5rem 0; }
        h3{ font-size:1.3rem; line-height:2rem; text-transform:uppercase; color:#fff; font-weight:600; letter-spacing:1.9px; margin:0; }
        .speed .icon svg{ width:auto; height:2.5rem; color:transparent; }
        .agility .icon svg{ width:auto; height:1.8rem; color:transparent; }
        .explosion .icon svg{ width:auto; height:1.9rem; color:transparent; }

        p{ font-size:2.1rem; line-height:2.4rem; margin:2rem 0 0 0; font-weight:500; color:#fff; }
        &>div>div>span{ display:block; }
        &>div>div:nth-of-type(2)>span:nth-of-type(1){ font-family:Poppins, sans-serif; font-size:5rem; line-height:7.5rem; color:#fff; font-weight:600; position:relative;
          &:before{ content:''; width:2.4rem; height:2.4rem; background:url('../../resources/images/star.png') no-repeat center; background-size:contain; position:absolute; margin:2rem 0 0 -3.5rem; }
        }
        .label{ font-size:1.4rem; line-height:1.8rem; color:#fff; display:block; }
        .play{ height:4.4rem; background:#3F8CFF; border-radius:1.2rem; display:inline-flex; align-items:center; margin:2.5rem 0 0 0; padding:1rem 2.3rem; transition:all .3s;
          span{ width:1.4rem; height:1.4rem; display:flex; justify-content:center; align-items:center; margin:0 0.7rem 0 0;
            svg{ display:block !important; width:1.4rem; height:1.4rem; z-index:3; color:transparent; }
          }
          &:hover{ background:#095cb5; }
        }
      }
    }
  }

  .photos{
    header{ margin:7rem 0 3rem 0; display:flex; justify-content:space-between; align-items:center;
      h2{ margin:0; }
      button{ color:#808191; text-decoration:underline; padding:0; margin:0; border:none;
        &:hover{ text-decoration:none; background:transparent; }
      }
    }
  }
  h2{ font-size:2.4rem; line-height:3.2rem; font-weight:500; color:#fff; margin:3rem 0; }
}

.photosList{
  &>div>div>div>div{ margin-bottom:2.4rem;
    &:nth-of-type(4n+1){ flex:1 0 60%; max-width:60%; }
    &:nth-of-type(4n+2){ flex:1 0 40%; max-width:40%; }
    &:nth-of-type(4n+3){ flex:1 0 40%; max-width:40%; }
    &:nth-of-type(4n+4){ flex:1 0 60%; max-width:60%; }
  }
  .card{ border-radius:1rem; background:#32343A; position:relative; border:none; cursor:pointer;
    &>div{ padding:0; }
    &>div:nth-of-type(2){ position:absolute; bottom:0; left:0; right:0; padding:0 3rem 4rem 3rem;
      &:after{ content:''; width:4.4rem; height:4.4rem; border-radius:50%; background:#3F8CFF url(../../resources/images/ico-play.svg) no-repeat; background-position:1.5rem center; background-size:1.6rem auto; margin:2rem 0 0 0; transition:all .3s; }
    }
    .meta{ font-size:1.8rem; line-height:2.6rem; color:#fff; font-weight:500; }
    .image{ height:35rem; }
    &:hover{
      &>div:nth-of-type(2){
        &:after{ background-color:#095cb5; }
      }
    }
  }
}




/* mobile */
.mobileHeaderBackground{ position:absolute; left:0; right:0; top:0; height:33rem; display:none;;
  .headerBackgroundImage{ background-image:url('../../resources/images/headerEvents-mob.jpg'); background-repeat:no-repeat; background-position:left top; background-size:cover; width:100%; height:100%; }
  .headerBackgroundGradient{ background:linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%); position:absolute; left:0; right:0; top:0; bottom:0;; }
}
.topNavi{ display:flex; justify-content:space-between; margin:0 -0.5rem 2.5rem -0.5rem;
  .buttonDiv{ flex:1 0 50%; max-width:50%; padding:0 0.5rem;
    button{ border:none; width:100%; height:5.6rem; background:#242731; color:#A0A1AB; border-radius:1.2rem; font-size:1.6rem; line-height:2rem; padding:1.8rem 2rem 1.8rem 5.6rem; position:relative; text-align:left; font-weight:500; transition:all .3s;
      &:hover{ color:#fff; background:#3F8CFF;
        &:before{ opacity:1; }
      }
      &:before{ content:''; display:block; background-repeat:no-repeat; background-position:center; left:1.7rem; top:0; width:3rem; height:100%; opacity:0.4; }
    }
    .events:before{ background-color:transparent; background-image:url('../../resources/images/ico-document.svg'); background-size:1.8rem auto; background-position:0.6rem center; }
    .tasks:before{ background-color:transparent; background-image:url('../../resources/images/ico-tasks.svg'); background-size:3rem auto; background-position:center; }
  }
}


/* rwd */
@media only screen and (max-width: 1200px){
  .player .records .recordsData{ padding:3rem 0; }
}


@media only screen and (max-width: 1100px){
  .player header{
    .playerInfo h1{ margin-top:3.5rem; }
    .playerScore{ flex:1 0 100%; max-width:100%; order:3; display:flex; margin-bottom:3rem;
      .score{ margin-top:0; }
    }
    .playerStats{ order:2; }
    .picture{ flex:1 0 18rem; max-width:18rem; height:18rem; }
  }
}


@media only screen and (max-width: 1050px){
  .player .records .recordsData{
    .record{
      p{ font-size:1.8rem; line-height:2.2rem; }
      .label{ font-size:1.3rem; line-height:1.6rem; }
      &>div > div:nth-of-type(2) > span:nth-of-type(1){ font-size:4rem; line-height:6rem;
        &:before{ width:2rem; height:2rem; margin:1.5rem 0 0 -2.5rem; }
      }
    }
  }
}


@media only screen and (max-width: 1000px){
  .photosList{
    &>div>div>div{ margin-left:-0.8rem !important; margin-right:-0.8rem !important;
      &>div{ margin-bottom:0;
        &:nth-of-type(4n+1), &:nth-of-type(4n+2), &:nth-of-type(4n+3), &:nth-of-type(4n+4){ flex:1 0 100%; max-width:100%; }
        &:nth-of-type(3n+2), &:nth-of-type(3n+3){ flex:1 0 50%; max-width:50%; }
        &>div{ padding:0 0.8rem !important; }
      }
    }
    .card{
      &>div:nth-of-type(2){ padding:0 2rem 3rem 2rem; }
      .image{ height:30rem; } 
    }
  }
}


@media only screen and (max-width: 767px){
  .mobileHeaderBackground{ display:block; }
}


@media only screen and (max-width: 550px){
  .player{
    h2{ font-size:1.6rem; line-height:2rem; text-align:center; }
    header{ flex-direction:column; align-items:center;
      .playerInfo{ padding:0;
        h1{ margin-top:1rem; font-size:3rem; line-height:3.6rem; }
        ul{ justify-content:center; }
        .misc{ text-align:center; }
      }
      .playerScore{ flex:1 0 100%; max-width:100%; order:3; display:flex; margin-bottom:3rem;
        .score{ margin-top:0; }
      }
      .playerStats{ order:2;
        ul{ justify-content:center;
          li{ font-size:1.5rem; padding:1rem 1.7rem; }
        }
      }
    }
    .about{ text-align:center; }
    .picture{ flex:1 0 12rem; max-width:12rem; height:12rem; }

    .records .recordsData{
      .record{ padding:0;
        .speed .icon svg{ height:1.8rem; }
        .agility .icon svg{ height:1.6rem; }
        .explosion .icon svg{ height:1.8rem; }
        h3{ font-size:1rem; line-height:1.5rem; }
        p{ font-size:1.3rem; line-height:1.6rem; }
        .icon{ height:1.8rem; margin:0 0 0.2rem 0; }
        .label{ font-size:1.1rem; line-height:1.4rem; color:#808191; margin:0 auto; max-width:8rem; }
        &>div > div:nth-of-type(2) > span:nth-of-type(1){ font-size:2.5rem; line-height:4rem;
          &:before{ width:1.6rem; height:1.6rem; margin:1rem 0 0 -2rem; }
        }
        .play{ font-size:0; width:3.8rem; height:3.8rem; border-radius:1.9rem; padding:0; justify-content:center; margin:2rem 0 0 0;
          span{ margin:0 0 0 0.1rem; }
        }
      }
    }
  }
}


@media only screen and (max-width: 450px){
  .photosList .card{
    &>div:nth-of-type(2){ padding:0 2rem 2rem 2rem; }
    .image{ height:21rem; }
    .meta>div>div{ font-size:1.4rem; line-height:2rem; }
  }
}


@media only screen and (max-width: 380px){
  .player{
    .records .recordsData{ flex-direction:column;
      .record{ flex:1 0 100%; max-width:100%; margin-bottom:5rem;
        &:last-of-type{ margin-bottom:0; }
        .speed .icon svg{ height:2.5rem; }
        .agility .icon svg{ height:1.8rem; }
        .explosion .icon svg{ height:1.9rem; }
        h3{ font-size:1.3rem; line-height:2rem; }
        p{ font-size:1.8rem; line-height:2.2rem; }
        .icon{ height:2.5rem; margin:0 0 0.5rem 0; }
        .label{ font-size:1.3rem; line-height:1.6rem; margin:0 1rem; max-width:100%; }
        &>div > div:nth-of-type(2) > span:nth-of-type(1){ font-size:4rem; line-height:6rem;
          &:before{ width:2rem; height:2rem; margin:1.5rem 0 0 -2.5rem; }
        }
      }
    }
  }
}