.toolbar{ display:flex; justify-content:space-between; align-items:center; margin:0 0 4rem 0;
  h1{ font-size:3.6rem; line-height:4rem; color:#fff; font-weight:500; margin:0 0 0 0; position:relative; z-index:2; }
  .add{ height:4.4rem; background:#3f8cff; border-radius:1.3rem; font-size:1.4rem; line-height:1.6rem; border:none; padding:1.4rem 2.4rem; color:#fff; font-weight:500; transition:all .3s;
    &:hover{ color:#3f8cff; background:#fff; }
  }
}

.table{ padding:0 0 4rem 0; overflow-x:auto;
  table{ background:#1F2128; margin:0 0 4rem 0;
    thead{
      tr{
        th{ font-size:1.1rem; line-height:1.6rem; color:#fff; text-transform:uppercase; border-bottom:none; padding:2rem 1.5rem; }
      }
    }
    tbody{
      &>tr{
        &>td{ padding:1.5rem 1.5rem; border-bottom:none;
          a{ color:inherit;
            &:hover{ color:inherit; text-decoration:underline; }
          }
        }
        &:nth-of-type(odd) td{ background:#1F2128; }
        &:nth-of-type(even) td{ background:#242731; }
        &:last-of-type{
          td:first-of-type{ border-bottom-left-radius:1.2rem; }
          td:last-of-type{ border-bottom-right-radius:1.2rem; }
        }
      }
    }
  }

  ul{
    li{ font-weight:600;
      a{ transition:all .3s; }
    }
  }
}


@media only screen and (max-width: 600px){
  .toolbar{ flex-direction:column; align-items:flex-start;
    .add{ margin-top:3rem; }
  }
}