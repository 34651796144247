.divider {
    margin: 2.5rem 4rem;
    background: #e4e4e4;
    opacity: 0.1;
    min-width: auto;
    width: auto;
}

.goback {
    padding: 0 2rem;
    .anticon {
        opacity: 0.4;
        width: 2rem;
        height: 2rem;
        color: transparent;
        transition: all 0.3s;

        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .ant-menu-title-content {
        color: #808191;
        margin-left: 1.5rem;
    }
}
