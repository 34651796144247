.search {
    width: 500px;
}

.card {
    .image {
        height: 150px;
        background: rgba(255, 255, 255, 0.3);
    }
}
