.divider{ margin:2rem 4rem 3.5rem 4rem; background:#e4e4e4; opacity:0.1; min-width:auto; width:auto; }

.header{
  .back{ width:4.4rem; height:4.4rem; margin:1rem 0 0 2rem;
    span{ width:4.4rem; height:4.4rem; display:flex; justify-content:center; align-items:center;;
      svg{ display:block !important; width:2.4rem; height:2.4rem; z-index:3; color:transparent; }
    }
  }
  .headerBackground{ position:absolute; left:0; right:0; top:0; height:42rem;
    .headerBackgroundImage{ background-image:url('../../../../resources/images/headerEvents.jpg'); background-repeat:no-repeat; background-position:left top; background-size:cover; width:100%; height:100%; }
    .headerBackgroundGradient{ background:linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%); position:absolute; left:0; right:0; top:0; bottom:0;; }
  }
  h1{ font-size:3.6rem; line-height:4rem; color:#fff; font-weight:500; margin:11.4rem 0 0 0; position:relative; z-index:2; }
  p{ font-size:1.6rem; line-height:2.6rem; color:#fff; font-weight:300; margin:1rem 0 0 0; position:relative; z-index:2; }

  .mobileHeaderBackground{ position:absolute; left:0; right:0; top:0; height:33rem; z-index:2;
    .headerBackgroundImage{ background-image:url('../../../../resources/images/headerEvents-mob.jpg'); background-repeat:no-repeat; background-position:left top; background-size:cover; width:100%; height:100%; }
    .headerBackgroundGradient{ background:linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%); position:absolute; left:0; right:0; top:0; bottom:0;; }
  }
}


@media only screen and (max-width: 767px){
  .header{ margin-left:-14.6rem; width:100vw;
      h1{ margin-top:2rem; text-align:center; max-width:26rem; margin-left:auto; margin-right:auto; font-weight:600; font-size:2.4rem; line-height:2.8rem; }
      p{ text-align:center; font-size:1.5rem; line-height:2.2rem; margin:1rem 2rem 0 2rem; }
  }
}



@media only screen and (max-width: 500px){
  .header{ margin-left:-13.1rem;
      .back{ margin:1rem 0 0 0.5rem; }
  }
}