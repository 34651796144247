.result h3 {
    font-size: 35px;
}

.result h4 {
    font-size: 16px;
}

.result .image {
    font-size: 329px;
}
